import { Box, Button, Stack, Typography } from '@mui/material';
import Confetti from 'react-confetti';
import { useLocation, useNavigate } from 'react-router-dom';
import { pdf, PDFViewer } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { toast } from 'react-hot-toast';
import React, { useState } from 'react';
import useLocales from '#/hooks/useLocales';
import UploadSignedDoc from '#/components/shared/UploadSignedDoc';
import useDisableDownloads from '#/hooks/useDisableDownloads';
import BlurryPurchaseCover from '#/components/shared/ui/BlurryPurchaseCover';
import useAuth from '#/hooks/useAuth';
import { useGetServiceForm, useSubmitDocument } from '#/api/servicesQueries';
import Output from '#/components/pages/MarriageContract/steps/DocumentOutput/document';
import {
  useGetAssets,
  useGetContractParties,
  useGetProperties,
} from '#/api/marriageContract';
import { useUpdateProfile } from '#/api/userQueries';
import { useGetChildren } from '#/api/childrenQueries';
import HighlightedTextFormat from '#/components/pages/MarriageContract/steps/DocumentOutput/highlight-formats';
import ModalWrapper from '#/components/shared/advisor-helpers/modal-wrapper';
import AdvisorsListView from '#/components/shared/advisor-helpers/partner/advisors-list-view';
import NonPartnerView from '#/components/shared/advisor-helpers/non-partner/non-partner-view';

export default function DocumentOutput() {
  const [openAdvisorModal, setOpenAdvisorModal] = useState(false);

  const { user, refetch } = useAuth();
  const { translate } = useLocales();
  const { state } = useLocation();
  const navigate = useNavigate();

  const { data: outputValues } = useGetServiceForm('marriage-contract/get');
  const { data: contractParties } = useGetContractParties();
  const { data: estateAssets } = useGetAssets();
  const { data: properties } = useGetProperties();
  const { data: children } = useGetChildren();
  const { mutateAsync: updateProfile } = useUpdateProfile();
  const { mutateAsync: submitDocument, isLoading: submitDoc } =
    useSubmitDocument('marriage-contract/update');
  const { handleDisableDownloads } = useDisableDownloads('marriage_contract');

  const personalAssets = estateAssets?.results.filter(
    (asset) => asset?.previous_owner === contractParties?.results[1]?.id
  );

  const spouseAssets = estateAssets?.results.filter(
    (asset) => asset?.previous_owner === contractParties?.results[0]?.id
  );

  const translatedNextSteps = Array.from({ length: 3 }, (_, i) =>
    translate(`marriageContract.stepFour.nextStep${i + 1}`)
  );

  const isPartner = user?.partner;

  const handleOpenAdvisorModal = () => setOpenAdvisorModal(true);
  const handleCloseAdvisorModal = () => setOpenAdvisorModal(false);

  const handleContinueProcess = () => {
    setOpenAdvisorModal(false);
    const targetURL =
      'https://app.getyourlawyer.ch/gateway/consultancy?utm_source=gutgeregelt.ch&utm_medium=referral&utm_campaign=may2024';
    window.open(targetURL, '_blank');
    navigate('/dashboard/home');
  };

  const generatePDFDocument = async () => {
    const blob = await pdf(
      <Output
        outputValues={{
          user: {
            ...user,
            ...contractParties?.results[1],
          },
          spouseInfo: contractParties?.results[0],
          date_of_marriage: outputValues?.date_of_marriage,
          place_of_marriage: outputValues?.place_of_marriage,
          number_of_children: outputValues?.number_of_children,
          have_children: outputValues?.have_children,
          acquired_after_marriage: outputValues?.acquired_after_marriage,
          acquired_property_after_marriage:
            outputValues?.acquired_property_after_marriage,
          maintain_ordinary_property_regime:
            outputValues?.maintain_ordinary_property_regime,
          legally_optimal_choice: outputValues?.legally_optimal_choice,
          spouse_disposal_right: outputValues?.spouse_disposal_right,
          disposal_fixed_amount: outputValues?.disposal_fixed_amount,
          disposal_percentage: outputValues?.disposal_percentage,
          children,
          personalAssets,
          spouseAssets,
          properties,
        }}
      />
    ).toBlob();

    const formData = new FormData();
    formData.append(
      'document',
      blob,
      `${String(
        translate('global.services.marriageContract')
      )}_${contractParties?.results[1]?.first_name}_${contractParties
        ?.results[1]?.last_name}_${new Date()
        .toLocaleDateString('en-GB')
        .split('/')
        .join('-')}.pdf`
    );
    formData.append('step', '3');

    await submitDocument(formData)
      .then((res) => {
        const targetURL = res?.data?.document;
        window.open(targetURL, '_blank');
      })
      .catch(() => {
        toast.error(
          String(translate('toast_notifications.error.document_submit'))
        );
      });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    await updateProfile({
      // eslint-disable-next-line no-unsafe-optional-chaining
      downloaded: ['marriage_contract'],
    }).then(() => refetch());
  };

  const hideConfetti =
    user?.downloaded?.includes('marriage_contract') || state?.markAsDone;

  return (
    <Box>
      {!hideConfetti && (
        <Confetti
          recycle={false}
          numberOfPieces={400}
          style={{ width: '100%' }}
        />
      )}
      <Stack>
        <Typography gutterBottom>
          {String(translate('marriageContract.stepFour.nextStepsTitle'))}
        </Typography>
        {translatedNextSteps.map((step, i) => (
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            key={`key-steps${i}`}
            sx={{ marginY: 1 }}
          >
            <Typography gutterBottom>{i + 1}.</Typography>
            <Typography gutterBottom>
              <HighlightedTextFormat
                context={String(step)}
                targets={['klicken Sie hier']}
                onClick={handleOpenAdvisorModal}
              />
            </Typography>
          </Stack>
        ))}
        <UploadSignedDoc
          serviceName="marriage-contract/update"
          documentType="marriageContract"
          serviceInvalidateKey="marriage-contract/get"
        />
      </Stack>
      {!state?.markAsDone && (
        <Box
          sx={{
            width: {
              xs: '100%',
              sm: '100%',
              md: '50%',
            },
            paddingTop: '20px',
            margin: '0 auto',
          }}
        >
          {handleDisableDownloads() ? (
            <BlurryPurchaseCover
              service="marriageContract.stepFour.document"
              user={user}
            />
          ) : (
            <PDFViewer
              style={{
                width: '100%',
                height: '550px',
              }}
              showToolbar={false}
            >
              <Output
                outputValues={{
                  user: {
                    ...user,
                    ...contractParties?.results[1],
                  },
                  spouseInfo: contractParties?.results[0],
                  date_of_marriage: outputValues?.date_of_marriage,
                  place_of_marriage: outputValues?.place_of_marriage,
                  number_of_children: outputValues?.number_of_children,
                  have_children: outputValues?.have_children,
                  acquired_after_marriage:
                    outputValues?.acquired_after_marriage,
                  acquired_property_after_marriage:
                    outputValues?.acquired_property_after_marriage,
                  maintain_ordinary_property_regime:
                    outputValues?.maintain_ordinary_property_regime,
                  legally_optimal_choice: outputValues?.legally_optimal_choice,
                  spouse_disposal_right: outputValues?.spouse_disposal_right,
                  disposal_fixed_amount: outputValues?.disposal_fixed_amount,
                  disposal_percentage: outputValues?.disposal_percentage,
                  children,
                  personalAssets,
                  spouseAssets,
                  properties,
                }}
              />
            </PDFViewer>
          )}
          <Button
            disabled={handleDisableDownloads() || submitDoc}
            variant="contained"
            color="primary"
            onClick={generatePDFDocument}
            sx={{ width: 1, mt: 2 }}
          >
            <Typography>{String(translate('global.download'))}</Typography>
          </Button>
        </Box>
      )}
      <ModalWrapper
        open={openAdvisorModal}
        onClose={handleCloseAdvisorModal}
        modalTitle="global.legalAdvisorsModal.title"
        modalDescription={
          isPartner ? '' : `global.legalAdvisorsModal.description`
        }
      >
        {isPartner ? (
          <AdvisorsListView
            advisorType="legal"
            closeModal={handleCloseAdvisorModal}
          />
        ) : (
          <NonPartnerView
            advisorImage="/assets/images/getyourlawyer_logo.svg"
            isComplexForm={false}
            handleContinueProcess={handleContinueProcess}
            isFinancialAdvisor={false}
          />
        )}
      </ModalWrapper>
    </Box>
  );
}
