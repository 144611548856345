import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { IProperty } from '#/types/marriageContract';
import {
  invalidateMarriageContractQueries,
  useDeleteProperty,
} from '#/api/marriageContract';
import useLocales from '#/hooks/useLocales';
import Iconify from '#/components/shared/ui/Iconify';

type PropertyDetailsProps = {
  property: IProperty;
  onEdit?: () => void;
};

export default function PropertyDetails({
  property,
  onEdit,
}: PropertyDetailsProps) {
  const { mutateAsync: deleteProperty } = useDeleteProperty(property?.id);

  const handleDelete = async () => {
    try {
      await deleteProperty(property?.id).then(() => {
        invalidateMarriageContractQueries.getProperties();
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      sx={{
        my: 1,
        display: {
          sm: 'block',
          md: 'flex',
        },
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box>
        <InfoLabel
          isPropertyValue
          label="global.formLabels.propertyType"
          value={property?.property_type}
        />
        <InfoLabel
          label="global.formLabels.city"
          value={property?.address?.city}
        />
      </Box>
      <ActionButtons onEdit={onEdit} onDelete={handleDelete} />
    </Box>
  );
}

type InfoLabelProps = {
  label: string;
  value: string | number;
  isPropertyValue?: boolean;
};

const InfoLabel = ({ label, value, isPropertyValue }: InfoLabelProps) => {
  const { translate } = useLocales();

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography
        variant="subtitle1"
        noWrap
        fontStyle="italic"
        sx={{ width: '150px' }}
      >
        {String(translate(label))}:
      </Typography>
      <Stack direction="row" alignItems="center">
        <Typography variant="body1" noWrap>
          {isPropertyValue
            ? String(translate(`marriageContract.propertyTypes.${value}`))
            : value}
        </Typography>
      </Stack>
    </Stack>
  );
};

type ActionButtonsProps = {
  onEdit?: () => void;
  onDelete?: () => void;
};

const ActionButtons = ({ onEdit, onDelete }: ActionButtonsProps) => {
  const { translate } = useLocales();

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Button
        variant="text"
        color="error"
        size="small"
        onClick={onDelete}
        startIcon={
          <Iconify
            icon="material-symbols:delete-outline"
            width={16}
            height={16}
          />
        }
      >
        <Typography variant="caption">
          {String(translate('global.delete'))}
        </Typography>
      </Button>
      <Button
        variant="text"
        size="small"
        onClick={onEdit}
        startIcon={
          <Iconify icon="material-symbols:edit" width={16} height={16} />
        }
      >
        <Typography variant="caption">
          {String(translate('global.edit'))}
        </Typography>
      </Button>
    </Stack>
  );
};
