import {
  Container,
  Typography,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Card,
  Button,
  Divider,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import useStepper from '#/hooks/useStepper';
import { stepsData } from '#/components/pages/MarriageContract/stepsData';
import useLocales from '#/hooks/useLocales';
import { FormProvider } from '#/components/shared/hook-form';
import useServiceStats from '#/hooks/useServiceStats';
import { QontoConnector } from '#/components/pages/LivingWill/Steps/styles';
import QontoStepIcon from '#/components/pages/LivingWill/Steps/QontoStepIcon';
import EnoughForNowModal from '#/components/shared/ui/EnoughForNowModal';
import { IMarriageContract } from '#/types/marriageContract';
import {
  invalidateServicesStatsQueries,
  useGetServiceForm,
  useStartService,
} from '#/api/servicesQueries';
import {
  schemaValues,
  stepOneSchema,
  stepThreeSchema,
  stepTwoSchema,
} from './schemaValidations';
import Iconify from '#/components/shared/ui/Iconify';
import { invalidateMarriageContractQueries } from '#/api/marriageContract';
import { useGetChildren } from '#/api/childrenQueries';
import useAuth from '#/hooks/useAuth';
import { resetSwissSeparator } from '#/utils/formatNumber';
import ModalWrapper from '#/components/shared/advisor-helpers/modal-wrapper';
import AdvisorsListView from '#/components/shared/advisor-helpers/partner/advisors-list-view';
import NonPartnerView from '#/components/shared/advisor-helpers/non-partner/non-partner-view';
import MarkAsDone from '#/components/shared/mark-as-done';

export default function MarriageContractForm() {
  const [stopProcess, setStopProcess] = useState<boolean>(false);
  const [openEnoughNow, setOpenEnoughNow] = useState<boolean>(false);

  const navigate = useNavigate();
  const { user } = useAuth();
  const { translate } = useLocales();
  const { state } = useLocation();
  const { marriage_contract } = useServiceStats();
  const { data: children } = useGetChildren();

  const { mutateAsync: startMarriageContract } = useStartService(
    'marriage-contract/update'
  );
  const { data: initialMCData } = useGetServiceForm(
    'marriage-contract/get',
    true
  );

  const { activeStep, setActiveStep, handleBack, handleNext } = useStepper(
    false,
    'marriage_contract',
    stepsData
  );

  const handleOpenEnoughNow = () => setOpenEnoughNow(true);
  const handleCloseEnoughNow = () => setOpenEnoughNow(false);

  const handlStopProcess = () => setStopProcess(true);
  const handleContinueProcess = () => {
    setStopProcess(false);
    window.open(
      'https://app.getyourlawyer.ch/gateway/consultancy?utm_source=gutgeregelt.ch&utm_medium=referral&utm_campaign=may2024',
      '_blank'
    );
    navigate('/dashboard/home');
  };

  const defaultValues = useMemo(
    () => schemaValues(user, initialMCData, activeStep, children?.length),
    [initialMCData, activeStep]
  );
  const schemaSteps = [
    stepOneSchema,
    stepTwoSchema(children?.length),
    stepThreeSchema,
  ];

  const methods = useForm<IMarriageContract>({
    resolver: schemaSteps[activeStep]
      ? yupResolver(schemaSteps[activeStep] as any)
      : undefined,
    defaultValues,
    reValidateMode: 'onChange',
  });
  const { handleSubmit, reset, setValue, watch } = methods;

  const submitForm = async (data: IMarriageContract) => {
    const savingToast = toast(String(translate('global.saving')), {
      icon: <Iconify icon="arcticons:fastsave" />,
    });

    try {
      const reqBody = {
        ...data,
        ...Object.fromEntries(
          Object.entries(data).map(([key, value]) => [
            key,
            value === '' ? null : value,
          ])
        ),
        disposal_fixed_amount: resetSwissSeparator(
          data.disposal_fixed_amount.toString()
        ),
        date_of_marriage: data.date_of_marriage
          ? new Date(data.date_of_marriage).toISOString().split('T')[0]
          : null,
        spouse: {
          ...data.spouse,
          birthday: data.spouse.birthday
            ? new Date(data.spouse.birthday).toISOString().split('T')[0]
            : null,
        },
        self: {
          ...data.self,
          birthday: data.self.birthday
            ? new Date(data.self.birthday).toISOString().split('T')[0]
            : null,
        },
      };

      await startMarriageContract(reqBody).then(() => {
        toast.dismiss(savingToast);
        handleNext();
        invalidateServicesStatsQueries.getServiceForm('marriage-contract/get');
        invalidateServicesStatsQueries.getServicesStats();
        invalidateMarriageContractQueries.getContractParties();
        scrollToTop();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (data: IMarriageContract) => {
    await submitForm(data);
  };

  const translatedStepsData = stepsData?.map((step, i) => ({
    ...step,
    label: translate(`marriageContract.stepperInfo.${i}.label`),
    title: translate(`marriageContract.stepperInfo.${i}.title`),
  }));

  const maximiseBenefits = activeStep !== translatedStepsData.length - 1;
  const effectiveMarriageContract =
    activeStep === translatedStepsData.length - 1;

  useEffect(
    () => () => {
      const submit = async () => {
        await submitForm(watch());
      };

      submit().then((r) => r);
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    if (state?.step === 'history') {
      // eslint-disable-next-line no-unsafe-optional-chaining
      setActiveStep(translatedStepsData?.length - 1);
    }
  }, [state?.step]);

  useEffect(() => {
    if (initialMCData || activeStep) {
      reset(defaultValues);
    }
  }, [initialMCData, activeStep]);

  useEffect(() => {
    if (children?.length !== 0 && activeStep === 1) {
      setValue('have_children', true);
      setValue('number_of_children', Number(children?.length));
    }
  }, [children, activeStep]);

  useEffect(() => {
    if (
      (watch('maintain_ordinary_property_regime') === false ||
        watch('legally_optimal_choice') === false) &&
      activeStep === 2
    ) {
      handlStopProcess();
    }
  }, [
    watch('maintain_ordinary_property_regime'),
    watch('legally_optimal_choice'),
    activeStep,
  ]);

  const handleAnswerReset = () => {
    const maintainOrdinaryPropertyRegime = watch(
      'maintain_ordinary_property_regime'
    );
    const legallyOptimalChoice = watch('legally_optimal_choice');
    if (maintainOrdinaryPropertyRegime === false) {
      setValue('maintain_ordinary_property_regime', true);
    }
    if (legallyOptimalChoice === false) {
      setValue('legally_optimal_choice', true);
    }
    setStopProcess(false);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  if (
    state?.step === 'markAsDone' ||
    state?.step === 'history' ||
    state?.step === 'uploadDocument'
  ) {
    return (
      <MarkAsDone
        serviceName="marriage-contract/update"
        documentType="marriageContract"
        serviceInvalidateKey="marriage-contract/get"
      />
    );
  }

  return (
    <Container maxWidth="lg">
      {maximiseBenefits && (
        <Typography variant="h2" mb={5} textAlign="center">
          {String(translate('marriageContract.mainHeaders.maximiseBenefits'))}
        </Typography>
      )}
      {effectiveMarriageContract && (
        <Typography variant="h2" mb={5} textAlign="center">
          {String(
            translate('marriageContract.mainHeaders.effectiveMarriageContract')
          )}
        </Typography>
      )}
      <Stack sx={{ width: '100%' }} spacing={4}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<QontoConnector />}
            sx={{
              display: {
                xs: 'none',
                sm: 'flex',
              },
            }}
          >
            {translatedStepsData?.map((step, i) => (
              <Step
                key={`step-${step.title}`}
                onClick={() => {
                  if (i < activeStep) {
                    setActiveStep(i);
                  }
                  if (marriage_contract?.service?.completed) {
                    setActiveStep(i);
                  }
                }}
                sx={{
                  ...((i < activeStep ||
                    marriage_contract?.service?.completed) && {
                    cursor: 'pointer',
                  }),
                }}
              >
                <StepLabel StepIconComponent={QontoStepIcon}>
                  {String(step.label)}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <Card sx={{ p: 3, mt: 4 }}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="subtitle1" mb={2}>
                {String(translatedStepsData[activeStep]?.title)}
              </Typography>
              {activeStep !== translatedStepsData.length - 1 && (
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  sx={{ mb: 2 }}
                  onClick={handleOpenEnoughNow}
                >
                  <Typography
                    variant="body1"
                    color="error"
                    sx={{ cursor: 'pointer' }}
                  >
                    {String(translate('global.enoughForNow'))}
                  </Typography>
                </Button>
              )}
            </Stack>
            <Divider
              sx={{
                mb: 3,
              }}
            />
            {translatedStepsData[activeStep]?.content()}
            <Divider
              sx={{
                mt: 4,
              }}
            />
            <Stack
              direction={{
                xs: 'column',
                sm: 'row',
              }}
              justifyContent="flex-end"
              spacing={2}
              sx={{
                mt: 3,
              }}
            >
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                <Typography>{String(translate('global.back'))}</Typography>
              </Button>
              {activeStep === translatedStepsData.length - 1 && (
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate('/dashboard/home');
                  }}
                  color="success"
                  sx={{
                    color: '#fff',
                  }}
                >
                  <Typography>{String(translate('global.done'))}</Typography>
                </Button>
              )}
              {activeStep !== translatedStepsData.length - 1 && (
                <LoadingButton
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  <Typography>{String(translate('global.next'))}</Typography>
                </LoadingButton>
              )}
            </Stack>
          </Card>
        </FormProvider>
      </Stack>
      <EnoughForNowModal
        openEnoughNow={openEnoughNow}
        handleCloseEnoughNow={handleCloseEnoughNow}
      />

      <ModalWrapper
        open={stopProcess}
        onClose={handleContinueProcess}
        modalTitle="global.complexFormModal.title"
        modalDescription="global.complexFormModal.description"
      >
        {user?.partner ? (
          <AdvisorsListView
            advisorType="legal"
            isComplexForm
            changeAnswer={handleAnswerReset}
            closeModal={() => setStopProcess(false)}
          />
        ) : (
          <NonPartnerView
            advisorImage="/assets/images/getyourlawyer_logo.svg"
            isComplexForm
            changeAnswer={handleAnswerReset}
            handleContinueProcess={handleContinueProcess}
          />
        )}
      </ModalWrapper>
    </Container>
  );
}
