import { Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { RHFTextField } from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import { fSwissThousandSeparator } from '#/utils/formatNumber';

export default function DisposableAmountForm() {
  const { translate } = useLocales();
  const { setValue } = useFormContext();
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <RHFTextField
        name="disposal_fixed_amount"
        label={String(translate('global.formLabels.disposal_fixed_amount'))}
        onChange={(e) => {
          const formatted = fSwissThousandSeparator(e.target.value);
          setValue('disposal_fixed_amount', formatted);
        }}
      />
      <Typography>bzw</Typography>
      <RHFTextField
        name="disposal_percentage"
        label={String(translate('global.formLabels.disposal_percentage'))}
      />
    </Stack>
  );
}
